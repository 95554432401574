<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="false"
  >
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
      <!-- Logo -->
      <a [routerLink]="['/home']">
        <div class="mb-4 flex h-20 items-center px-8 pt-6">
          <img class="mx-auto w-32" src="assets/images/logo/accelup-logo-hires.webp" alt="Logo image" />
        </div>
      </a>
    </ng-container>
  </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col" *transloco="let t">
  <!-- Header -->
  <div
    class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none print:hidden sm:h-20 md:px-6"
  >
    <ng-container *ngIf="!isScreenSmall">
      <!-- Logo -->
      <a [routerLink]="['/home']">
        <div class="mx-2 flex items-center lg:mr-8">
          <div class="hidden lg:flex">
            <img class="w-32 dark:hidden" src="assets/images/logo/accelup-logo-hires.webp" />
            <img class="hidden w-32 dark:flex" src="assets/images/logo/accelup-logo-hires.webp" />
          </div>
          <img class="flex w-32 lg:hidden" src="assets/images/logo/accelup-logo-hires.webp" />
        </div>
      </a>
      <!-- Horizontal navigation -->
      <fuse-horizontal-navigation
        class="mr-2"
        [name]="'mainNavigation'"
        [navigation]="navigation.horizontal"
      ></fuse-horizontal-navigation>
    </ng-container>
    <!-- Navigation toggle button -->
    <ng-container *ngIf="isScreenSmall">
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button>
    </ng-container>
    <!-- Components -->
    <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2"></div>
    <user></user>
  </div>

  <!-- Content -->
  <div class="min-h-5/6 flex w-full flex-auto flex-col">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div class="z-49 grid h-auto w-full grid-cols-3 gap-2 bg-white px-6 pt-6 dark:bg-transparent print:hidden">
    <div class="col-span-3 flex flex-row items-center gap-2 md:col-span-1 md:ml-6">
      <img class="h-14 w-18 rounded-md" src="assets/images/logo/eu_emblem.png" />
      <p class="test-justify text-secondary font-medium">
        {{ t('footer.eu-text') }}
      </p>
    </div>
    <div class="col-span-3 flex flex-col flex-nowrap justify-self-center p-4 md:col-span-1 md:p-0">
      <a class="self-center text-xl font-bold md:self-auto">{{ t('footer.about') }}</a>
      <div class="grid w-full grid-cols-2 pt-2">
        <a [routerLink]="['/faq']">{{ t('footer.faq') }}</a>
        <a [routerLink]="['/terms-and-conditions']">{{ t('footer.terms-and-conditions') }}</a>
        <a [routerLink]="['/about-us']">{{ t('footer.about-us') }}</a>
        <a [routerLink]="['/privacy-policy']">{{ t('footer.privacy-policy') }}</a>
        <a [routerLink]="['/how-it-works']">{{ t('footer.how-it-works') }}</a>
      </div>
    </div>
    <div class="col-span-3 flex flex-col flex-nowrap justify-self-center md:col-span-1 md:mr-6 md:justify-self-end">
      <a class="self-center pb-2 text-xl font-bold md:self-auto">{{ t('footer.contact') }}</a>

      <div class="flex flex-row gap-2 pt-2">
        <a class="flex items-center" href="https://twitter.com/AccelUP_ENoLL">
          <mat-icon [svgIcon]="'feather:twitter'"></mat-icon>
        </a>
        <a class="flex items-center" href="https://www.facebook.com/accelupbyenoll">
          <mat-icon [svgIcon]="'feather:facebook'"></mat-icon>
        </a>
        <a class="flex items-center" href="https://www.linkedin.com/company/accelup-by-enoll">
          <mat-icon [svgIcon]="'feather:linkedin'"></mat-icon>
        </a>
        <a class="flex items-center" [routerLink]="['/contact']">
          <mat-icon [svgIcon]="'feather:message-circle'" color="primary"></mat-icon>
        </a>
      </div>
    </div>
  </div>
  <div class="z-49 w-full bg-white px-2 py-2 text-lg dark:bg-transparent print:hidden md:py-0">
    {{ t('footer.copyright-information') }}
  </div>
</div>
