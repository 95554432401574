import { Component, Inject, PLATFORM_ID} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterState } from '@angular/router';
import { GoogletagService } from './core/googletag/googletag.service';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{
    /**
     * Constructor
     */
    constructor(
        private router: Router,
        private titleService: Title,
        private googletagService: GoogletagService,
        @Inject(PLATFORM_ID) private platformId: object)
    {        
        this.handleRouteEvents();
    }

    handleRouteEvents():void {
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
            this.titleService.setTitle(title);
            this.googletagService.setPageView(title, event.urlAfterRedirects);
          }
        });
      }
    
      getTitle(state: RouterState, parent: ActivatedRoute): string[] {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
          data.push(parent.snapshot.data['title']);
        }
        if (state && parent && parent.firstChild) {
          data.push(...this.getTitle(state, parent.firstChild));
        }
        return data;
      }
}
