import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, tap} from 'rxjs';
import {Navigation} from 'app/core/navigation/navigation.types';
import {horizontalNavigation} from './navdata';
import {UserService} from '../user/user.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);


    constructor(
        private userService: UserService
    ) {
        userService.user$
            .pipe(
                tap(
                    (user) => {
                            const allowedNavigation = horizontalNavigation.filter(
                                navItem => navItem.role === undefined ? true : navItem.role.includes(user?.role)
                            );
                            const nav: Navigation = {
                                default: allowedNavigation,
                                compact: null,
                                futuristic: null,
                                horizontal: allowedNavigation
                            };
                            this.navigation.next(nav);
                    }
                )
            )
            .subscribe();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this.navigation.asObservable();
    }
}
