/* tslint:disable:max-line-length */
import {FuseNavigationItem} from '@fuse/components/navigation';
import {UserRole} from '../user/user.types';

export interface FuseNavigationItemWithRoles extends FuseNavigationItem {
    role?: string[];
}

export const horizontalNavigation: FuseNavigationItemWithRoles[] = [
    {
        id: 'how-it-works',
        title: 'How it works',
        type: 'basic',
        icon: 'heroicons_outline:question-mark-circle',
        link: '/how-it-works'
    },
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        role: [UserRole.basic, UserRole.advanced, UserRole.admin],
        icon: 'heroicons_outline:user',
        link: '/dashboard'
    },
    {
        id: 'project',
        title: 'Browse projects',
        type: 'basic',
        icon: 'heroicons_outline:cube-transparent',
        link: '/projects'
    },
    {
        id: 'messages',
        title: 'Messages',
        type: 'basic',
        icon: 'heroicons_outline:inbox',
        role: [UserRole.basic, UserRole.advanced, UserRole.admin],
        link: '/messages'
    },
    {
        id: 'admin',
        title: 'Admin',
        type: 'basic',
        icon: 'heroicons_outline:identification',
        role: [UserRole.admin],
        link: '/admin'
    }
];
