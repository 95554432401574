import {Injectable} from '@angular/core';
import { Component, Inject, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { environment } from 'environments/environment';
declare let gtag: any;


@Injectable({
  providedIn: 'root'
})
export class GoogletagService{
    
  renderer: Renderer2;
    constructor(
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: object)
    {
      this.renderer = this.rendererFactory.createRenderer(null, null);
        if (isPlatformBrowser(this.platformId))
          this.injectScripts();
    }

    injectScripts():void {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
      this.renderer.appendChild(this.document.body, script);
  
      const scriptBody = this.renderer.createElement('script');
      scriptBody.type = 'text/javascript';
      scriptBody.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());  
        gtag('config', '${environment.googleAnalyticsId}');
      `;
      this.renderer.appendChild(this.document.body, scriptBody);
    }

    setPageView(pageTitle, pagePath):void{
      const pageLocation = this.document.location.href;
      gtag('event', 'page_view', {
        page_title: pageTitle,
        page_path: pagePath,
        page_location: pageLocation
      })
    }

    setProjectView(pageTitle, pagePath, projectId):void{
      const pageLocation = this.document.location.href;
      gtag('event', 'project_view', {
        page_title: pageTitle,
        page_path: pagePath,
        page_location: pageLocation,
        projectId: projectId
      })
    }
    
}