import {NgcCookieConsentConfig} from 'ngx-cookieconsent';
import {environment} from '../environments/environment';

export const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.domain
    },
    palette: {
        popup: {
            background: '#000'
        },
        button: {
            background: '#f1d600'
        }
    },
    theme: 'edgeless',
    type: 'opt-out',
    content: {
        href: 'cookie-policy'
    }
};
