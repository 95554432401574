<!-- Button -->
<ng-container *transloco="let t">
  <button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
      <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
  </button>

  <mat-menu [xPosition]="'before'" #userActions="matMenu">
    <ng-container *ngIf="user; else noUser">
      <button mat-menu-item>
        <span class="flex flex-col leading-none">
          <span>{{ t('general.signed-in-as') }}</span>
          <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
        </span>
      </button>
      <mat-divider class="my-2"></mat-divider>
      <button mat-menu-item (click)="onClickProfile()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>{{ t('general.profile') }}</span>
      </button>
      <button mat-menu-item (click)="onClickSettings()">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>{{ t('general.settings') }}</span>
      </button>
      <mat-divider class="my-2"></mat-divider>
      <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>{{ t('general.sign-out') }}</span>
      </button>
    </ng-container>
    <ng-template #noUser>
      <button mat-menu-item (click)="onClickProfile()" [routerLink]="['sign-in']">
        <span>{{ t('general.sign-in') }}</span>
      </button>
      <button mat-menu-item (click)="onClickProfile()" [routerLink]="['sign-up']">
        <span>{{ t('general.sign-up') }}</span>
      </button>
    </ng-template>
  </mat-menu>
</ng-container>
