import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ExtraOptions, PreloadAllModules, RouterModule} from '@angular/router';
import {MarkdownModule} from 'ngx-markdown';
import {FuseModule} from '@fuse';
import {FuseConfigModule} from '@fuse/services/config';
import {FuseMockApiModule} from '@fuse/lib/mock-api';
import {CoreModule} from 'app/core/core.module';
import {appConfig} from 'app/core/config/app.config';
import {LayoutModule} from 'app/layout/layout.module';
import {AppComponent} from 'app/app.component';
import {appRoutes} from 'app/app.routing';
//import {ApmModule, ApmService} from '@elastic/apm-rum-angular';
import {ErrorHandler} from '@angular/core';
//import {ApmErrorHandler} from '@elastic/apm-rum-angular';
import {environment} from 'environments/environment';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MaintenanceComponent} from './pages/maintenance/maintenance.component';
import {PrivacyPolicyComponent} from './pages/privacy-policy/privacy-policy.component';
import {TranslocoModule} from '@ngneat/transloco';
import {Error404Component} from './pages/error-404/error-404.component';
import {NgcCookieConsentModule} from 'ngx-cookieconsent';
import {cookieConfig} from './cookieConfig';
import { GoogletagService } from './core/googletag/googletag.service';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};

@NgModule({
    declarations: [AppComponent, MaintenanceComponent, PrivacyPolicyComponent, Error404Component],
    imports: [
        //ApmModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),

        // Core module of your application
        CoreModule,

        TranslocoModule,
        // Layout module of your application
        LayoutModule,
        MatSnackBarModule,
        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
        NgcCookieConsentModule.forRoot(cookieConfig)
    ],
    providers: [
        GoogletagService
        //ApmService,
        //{provide: ErrorHandler, useClass: ApmErrorHandler}
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    // constructor(service: ApmService) {
    //     service.init({
    //         serviceName: 'enoll-client',
    //         serverUrl: 'http://accellup.med.auth.gr:8200',
    //         distributedTracingOrigins: [environment.apiUrl]
    //     });
    // }
}
