import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject, tap} from 'rxjs';
import {CreateServiceRequest, Service} from './services.types';
import {environment} from 'environments/environment';
import {SnackbarService} from '../../core/snackbar/snackbar.service';

@Injectable({
    providedIn: 'root'
})
export class ServicesService {

    public serviceSubject: ReplaySubject<Service[]> = new ReplaySubject(1);
    private services: Service[] = [];

    constructor(
        private http: HttpClient,
        private snackbarService: SnackbarService,
        ) {
    }

    get(): Observable<Service[]> {
        return this.http.get<Service[]>(`${environment.apiUrl}/Services`)
            .pipe(
                tap((data: Service[]) => {
                    this.services = data;
                    this.serviceSubject.next(data);
                })
            );
    }

    toggleServiceActive(service: Service): void {
        this.http.patch(`${environment.apiUrl}/Services/${service.id}`, {
            name: service.name,
            isActive: !service.isActive
        }).subscribe(
            (data: Service) => {
                const idx = this.services.findIndex(s => s.id === service.id);
                if (idx !== -1) {
                    this.services[idx] = data;
                    this.serviceSubject.next(this.services);
                }
            }
        );
    }

    deleteService(service: Service): void {
        this.http.delete(`${environment.apiUrl}/Services/${service.id}`).subscribe(
            {
                next: (data: Record<string, never>) => {
                    const idx = this.services.findIndex(s => s.id === service.id);
                    if (idx !== -1) {
                        this.services.splice(idx, 1);
                        this.serviceSubject.next(this.services);
                    }
                },
                error: () => this.snackbarService.showMessage('project.list.cannot-delete')
            }
        );
    }

    createService(service: CreateServiceRequest): void {
        this.http.post(`${environment.apiUrl}/Services`, {
            ...service,
            isActive: true
        }).subscribe(
            (data: Service) => {
                this.services.push(data);
                this.serviceSubject.next(this.services);
            }
        );
    }
}
