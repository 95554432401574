import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RatingComponent } from './components/rating.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ChooseRatingComponent } from './components/choose-rating.component';
import { FuseAlertModule } from '@fuse/components/alert';

@NgModule({
  declarations: [RatingComponent, ChooseRatingComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatIconModule, MatButtonModule, FuseAlertModule],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, RatingComponent, ChooseRatingComponent, FuseAlertModule],
})
export class SharedModule {}
