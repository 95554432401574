import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html'
})
export class RatingComponent implements OnInit, OnChanges {

    @Input() rating: number = 0;
    ratingList = [];

    constructor() {
    }

    ngOnInit(): void {
        this.updateIcons();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateIcons();
    }

    updateIcons(): void {

        this.ratingList = [];
        for (let i = 0; i < Math.floor(this.rating); i++) {
            this.ratingList.push('star');
        }
        let remainingStars = 5 - Math.floor(this.rating);
        if (this.rating % 1 >= 0.5) {
            this.ratingList.push('star_half');
            remainingStars -= 1;
        }
        for (let i = 0; i < remainingStars; i++) {
            this.ratingList.push('star_border');
        }
    }

}
