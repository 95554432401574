import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {UserService} from 'app/core/user/user.service';
import {environment} from 'environments/environment';
import {RegisterRequest} from './model/register-request.types';
import {ForgotPasswordRequest, ResetPasswordRequest} from './model/forgot-password.types';
import {User} from '../user/user.types';
import {ChangePasswordRequest} from './model/change-password.types';
import {SnackbarService} from '../../core/snackbar/snackbar.service';

@Injectable()
export class AuthService {
    private authenticated = false;

    constructor(
        private httpClient: HttpClient,
        private userService: UserService,
        private snackbarService: SnackbarService
    ) {}

    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    forgotPassword(forgotPasswordRequest: ForgotPasswordRequest): Observable<Record<string, never>> {
        return this.httpClient.post<Record<string, never>>(`${environment.apiUrl}/Accounts/forgot-password`, forgotPasswordRequest);
    }

    resetPassword(resetPasswordRequest: ResetPasswordRequest): Observable<Record<string, never>> {
        return this.httpClient.post<Record<string, never>>(`${environment.apiUrl}/Accounts/reset-password`, resetPasswordRequest);
    }

    changePassword(changePasswordRequest: ChangePasswordRequest): Observable<Record<string, never>> {
        return this.httpClient.post<Record<string, never>>(`${environment.apiUrl}/Accounts/change-password`, changePasswordRequest);
    }

    signIn(credentials: { email: string; password: string }): Observable<User> {
        // Throw error, if the user is already logged in
        if (this.authenticated) {
            return throwError('User is already logged in.');
        }

        return this.httpClient.post(`${environment.apiUrl}/Accounts/authenticate`, credentials, {withCredentials: true}).pipe(
            switchMap((response: User) => {

                // Store the access token in the local storage
                this.accessToken = response.jwtToken;

                // Set the authenticated flag to true
                this.authenticated = true;

                // Store the user on the user service
                this.userService.user = response;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    refreshToken(): Observable<boolean> {
        // Renew token
        return this.httpClient.post(`${environment.apiUrl}/Accounts/refresh-token`, null, {withCredentials: true}).pipe(
            switchMap((response: any) => {
                // Store the access token in the local storage
                this.accessToken = response.jwtToken;

                // Set the authenticated flag to true
                this.authenticated = true;

                // Store the user on the user service
                this.userService.user = response;

                // Return true
                return of(true);
            }),
            catchError(() => {
                    this.signOut();
                    // Return false
                    return of(false);
                }
            )
        );
    }

    signOut(): Observable<boolean> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Also sign out from the user service
        this.userService.signOut();

        // Set the authenticated flag to false
        this.authenticated = false;

        // Return the observable
        return of(true);
    }

    signUp(user: RegisterRequest): Observable<Record<string, never>> {
        return this.httpClient.post<Record<string, never>>(`${environment.apiUrl}/Accounts/register`, user);
    }

    verifyEmail(token: string): Observable<Record<string, never>> {
        return this.httpClient.post<Record<string, never>>(`${environment.apiUrl}/Accounts/verify-email`, {
            token
        });
    }

    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this.httpClient.post('api/auth/unlock-session', credentials);
    }

    signInUsingToken(): Observable<boolean> {
        return this.httpClient.get(`${environment.apiUrl}/Accounts/verify-token`).pipe(
            switchMap((response: User) => {
                // Set the authenticated flag to true
                this.authenticated = true;

                // Store the user on the user service
                this.userService.user = response;

                return of(true);
            })
        );
    }

    check(): Observable<boolean> {
        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date and refresh
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return this.refreshToken();
        }
        // Check if the user is already logged in
        if (this.authenticated) {
            return of(true);
        }

        // @TODO: This might not be needed anymore because user is in localstorage.
        // If the access token exists and it didn't expire everything is ok
        return this.signInUsingToken();
    }
}
