import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AccountFilters, User, UserUpdateRequest } from 'app/core/user/user.types';
import { environment } from '../../../environments/environment';
import { Paginated, PaginationParams } from '../pagination/pagination.types';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

  constructor(private httpClient: HttpClient) {}

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  set user(value: User) {
    localStorage.setItem('user', JSON.stringify(value));
    // Store the value
    this._user.next(value);
  }

  signOut(): void {
    localStorage.removeItem('user');
    this._user.next(null);
  }

  get(): Observable<User> {
    const user = JSON.parse(localStorage.getItem('user'));
    this.user = user;
    return of(user);
  }

  getById(id: string): Observable<User> {
    return this.httpClient.get<User>(`${environment.apiUrl}/Accounts/${id}`);
  }

  getAllUsers(paginationParams: PaginationParams, filters: AccountFilters): Observable<Paginated<User>> {
    const params = { ...paginationParams, ...filters };
    return this.httpClient.get<Paginated<User>>(`${environment.apiUrl}/Accounts`, { params });
  }

  activateUser(userId): Observable<Record<string, never>> {
    return this.httpClient.post<Record<string, never>>(`${environment.apiUrl}/Accounts/${userId}/activate`, {});
  }

  deactivateUser(userId): Observable<Record<string, never>> {
    return this.httpClient.post<Record<string, never>>(`${environment.apiUrl}/Accounts/${userId}/deactivate`, {});
  }

  update(updateInformation: UserUpdateRequest): Observable<User> {
    return this.httpClient.put<User>(`${environment.apiUrl}/Accounts`, updateInformation).pipe(
      tap((response) => {
        this.user = response;
      })
    );
  }

  setUserOrganization(userId: string, organizationId: string): Observable<User> {
    return this.httpClient.post<User>(
      `${environment.apiUrl}/Accounts/${userId}/set-organization/${organizationId}`,
      {}
    );
  }
}
