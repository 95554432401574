<div class="flex items-center">
    <ng-container *ngFor="let x of ratingList; let i = index">
        <button
            (click)="onClick(i + 1)"
            mat-icon-button>
            <mat-icon
                class="text-orange-500">{{x}}</mat-icon>
        </button>
    </ng-container>
</div>
