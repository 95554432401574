import {Route} from '@angular/router';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';
import {InitialDataResolver} from 'app/app.resolvers';
import {AdminGuard} from './core/auth/guards/admin.guard';
import {MaintenanceComponent} from './pages/maintenance/maintenance.component';
import {Error404Component} from './pages/error-404/error-404.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    {path: '', pathMatch: 'full', redirectTo: 'home'},
            
    
    {path: 'maintenance', component: MaintenanceComponent, data: { title: 'Maintenance' }},
    {path: 'error-404', component: Error404Component, data: { title: 'Error 404' }},
    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'projects'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
            },
            {
                path: 'reset-password',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)
            },
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
            },
            {
                path: 'sign-up',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)
            },
            {
                path: 'verify-email',
                loadChildren: () => import('app/modules/auth/verify-email/verify-email.module').then(m => m.AuthVerifyEmailModule)
            }
        ]
    },
    // Non-authenticated routes
    {
        path: '',
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: '',
                loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)
            },
            {
                path: 'profile/:id',
                loadChildren: () => import('app/modules/profile/profile.module').then(m => m.ProfileModule)
            },
            {
                path: 'projects',
                loadChildren: () => import('app/modules/project/project.module').then(m => m.ProjectModule)
            },
            {
                path: 'sign-out',
                loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)
            },
        ]
    },
    // Authenticated routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('app/modules/user-dashboard/user-dashboard.module').then(m => m.UserDashboardModule)
            },
            {
                path: 'settings',
                loadChildren: () => import('app/modules/settings/settings.module').then(m => m.SettingsModule)
            },
            {
                path: 'messages',
                loadChildren: () => import('app/modules/messages/message.module').then(m => m.MessageModule)
            },
            {
                path: 'admin',
                canActivate: [AdminGuard],
                loadChildren: () => import('app/modules/admin/admin.module').then(m => m.AdminModule)
            }
        ]
    },
    // Redirect invalid path to '/example'
    { path: '**', redirectTo: 'home'}
];
