<div class="absolute inset-0" *transloco="let t; read: 'error-404'">
    <!-- Main -->
    <div class="flex flex-col flex-auto items-center justify-center h-full p-6 sm:p-10">
        <!-- @formatter:off -->
        <div class="w-full max-w-3xl">
            <img src="assets/images/error/error-404.svg">
        </div>
        <!-- @formatter:on -->
        <div class="mt-12 sm:mt-24 text-4xl md:text-7xl font-extrabold tracking-tight leading-tight md:leading-none text-center">{{ t('404') }}</div>
        <div class="mt-2 text-lg md:text-xl font-medium tracking-tight text-center text-secondary">{{ t('404-text') }}</div>
        <a
            class="mt-12 font-medium underline text-primary-500"
            [routerLink]="['/']">
            {{ t('back') }}
        </a>
    </div>
</div>
