import { Organization } from 'app/shared/models/organization';
import { Country } from '../country/country.types';
import { Service } from '../services/services.types';
import { Publication } from './publications/publication.types';

export interface CountryUpdate {
  id: number;
}

export interface Address {
  addressText: string;
  country: CountryUpdate;
}

export enum UserRole {
  admin = 'Admin',
  advanced = 'Advanced',
  basic = 'Basic',
}

export interface Role {
  id: number;
  name: UserRole;
}

export interface UserUpdateRequest {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  landlineNumber: string;
  address: Address;
  servicesIds: number[];
  role?: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  name: string;
  services: Service[];
  publications: Publication[];
  mobileNumber: string;
  landlineNumber: string;
  address: Address;
  email: string;
  role: UserRole;
  isVerified: boolean;
  isActive: boolean;
  createdAt: number;
  updatedAt: number;
  jwtToken: string;
  organization: Organization;
}

export type AccountFilters = {
  showInactive?: boolean;
  roleName?: string;
  hasOrganization?: boolean;
  searchQuery?: string;
};
