import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'choose-rating',
    templateUrl: './choose-rating.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ChooseRatingComponent
        }
    ]
})
export class ChooseRatingComponent implements OnInit, ControlValueAccessor {

    @Input() rating: number = 0;

    onChange = (rating): void => {
    };

    onTouched = (): void => {
    };

    ratingList = [];

    constructor() {
    }

    ngOnInit(): void {
        this.updateIcons();
    }

    updateIcons(): void {
        this.ratingList = [];
        for (let i = 0; i < this.rating; i++) {
            this.ratingList.push('star');
        }
        const remainingStars = 5 - Math.floor(this.rating);
        for (let i = 0; i < remainingStars; i++) {
            this.ratingList.push('star_border');
        }
    }

    registerOnChange(onChange: (rating: number) => void): void {
        this.onChange = onChange;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(rating: number): void {
        this.rating = rating;
        this.updateIcons();
    }

    onClick(rating: number): void {
        this.rating = rating;
        this.onChange(rating);
        this.updateIcons();
    }
}
