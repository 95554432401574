import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {TranslocoService} from '@ngneat/transloco';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {

    private config: MatSnackBarConfig = {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom'
    };

    constructor(
        private snackBar: MatSnackBar,
        private translocoService: TranslocoService
    ) {
    }

    public showMessage(message: string, translate: boolean = true): void {
        this.snackBar.open(translate ? this.translocoService.translate(message) : message,
            this.translocoService.translate('general.dismiss'),
            this.config);
    }
}
