<div class="w-full py-8"
     *transloco="let t; read: 'privacy-policy'"
>
    <div class="w-full text-center">
        <div class="text-3xl lg:text-4xl font-bold">{{ t('title') }}</div>
    </div>
    <div class="px-4 lg:px-20 mt-8">
        <p class="whitespace-pre-wrap">{{ t('text') }}</p>
    </div>
</div>
